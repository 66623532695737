import {
  HStack,
  IconButton,
  TableProps,
  Text,
  Link
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  PaginationState,
  OnChangeFn,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { MissionAttributes, MissionAttributesMeta } from '@/interfaces/mission'
import { RecordId } from '@/interfaces/id'
import StateBadge from '@/components/StateBadge/StateBadge'
import { formatDateShort } from '@/utils/datesFormat'
import { parseISO } from 'date-fns'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { missionRoute, missionSummaryRoute, missionsForStructureRoute } from '@/constants/routes'
import { CollectionTable } from '@/components/CollectionTable/CollectionTable'
import { useOrganizations } from '@/contexts/useOrganizationsContext'
import { UserInfo } from '@/components/UserInfo/UserInfo'
import { UserProfile } from '@/components/UserProfile/UserProfile'
import { truncate } from '@/utils/string'


const columnHelper = createColumnHelper<ApiResource<MissionAttributes, MissionAttributesMeta>>()


export interface MissionTableProps extends TableProps {
  data: ApiCollectionResponse<MissionAttributes, unknown, MissionAttributesMeta>
  onDelete: (missionId: RecordId) => Promise<void>
  onEdit: (missionId: RecordId) => Promise<void>
  onPaginationChange?: OnChangeFn<PaginationState> | undefined
}

export const MissionTable = ({ data, onDelete, onEdit, onPaginationChange, ...props }: MissionTableProps) => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()

  const columns = [
    columnHelper.accessor(row => row.attributes.name, {
      id: 'name',
      cell: info => <Link as={RouterLink} to={missionSummaryRoute(orgId, info.row.original.attributes.slug)}>{info.getValue()}</Link>,
      header: () => <span>Nom</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('meta.user.full_name', {
      header: 'De',
      cell: info => truncate(info.getValue(), 20),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.begin_at', {
      header: 'Début',
      cell: info => <Text>{formatDateShort(parseISO(info.getValue()))}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.end_at', {
      header: 'Fin',
      cell: info => <Text>{formatDateShort(parseISO(info.getValue()))}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('meta.structure.short_name', {
      header: () => <span>Structure</span>,
      cell: info => (
        <Link
          as={RouterLink}
          to={missionsForStructureRoute(orgId, info.row.original.attributes.structure_id)}
          color="fg.muted"
        >{info.getValue()}</Link>
      ),
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => (<HStack spacing="1">
        { ability?.can('update', 'mission', props.row.original?.attributes) && (
          <IconButton variant={'link'} icon={<FiEdit2 />} onClick={() => onEdit(props.row.original.id)} aria-label="Edit" />
        )}
        { ability?.can('delete', 'mission', props.row.original?.attributes) && (
          <IconButton variant={'link'} icon={<FiTrash2 />} onClick={() => onDelete(props.row.original.id)} aria-label="Delete" />
        )}
      </HStack>),
      footer: info => info.column.id,
    })
  ]

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data.meta?.pages ?? -1,
    state: {
      pagination: {
        pageIndex: data?.meta?.page ?? 0,
        pageSize: data?.meta?.items ?? 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange,
  })

  return (<CollectionTable table={table} {...props} />)
}
