import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react"
import { ApiErrorResponse } from "../../utils/api"
import axios, { AxiosError } from "axios"

interface ErrorBoxProps {
  error: ApiErrorResponse | AxiosError | null,
  message: string
}

const ErrorBox = ({ error, message }: ErrorBoxProps) => {

  const errorMessage: any = axios.isAxiosError(error) ? (error as AxiosError).response?.data : error

  return error ? (
    <Alert status='error'>
      <AlertIcon />
      <Box>
        <AlertTitle>{message}</AlertTitle>
        <AlertDescription>
          {errorMessage.error || ''}
        </AlertDescription>
      </Box>
    </Alert>
  ) : <></>

}


export default ErrorBox