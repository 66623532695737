import { formatDateShort } from "@/utils/datesFormat";
import { Button, HStack, IconButton, Text } from "@chakra-ui/react";
import { addMonths, startOfMonth } from "date-fns";
import { TbArrowLeft, TbArrowRight, TbCalendarExclamation } from "react-icons/tb";


export interface DateRangeHeaderProps {
  onDateChange: (newDate: Date) => void,
  date: Date
}

export function DateRangeHeader({
  onDateChange,
  date
}: DateRangeHeaderProps) {

  const onPrevious = () => {
    onDateChange(addMonths(date, -1))
  }

  const onNext = () => {
    onDateChange(addMonths(date, 1))
  }

  const onNow = () => {
    onDateChange(startOfMonth(new Date()))
  }

  return (
    <HStack justifyContent={'space-between'}>
      <IconButton aria-label="Précédent" icon={<TbArrowLeft />} onClick={onPrevious} />
      <HStack>
        <Text>{formatDateShort(date, {}, 'd LLL')} - {formatDateShort(addMonths(date, 1), {}, 'd LLL')}</Text>
        {/* <IconButton aria-label="Maintenant" icon={<TbCalendarExclamation />} onClick={onNow} /> */}
      </HStack>
      <IconButton aria-label="Suivant" icon={<TbArrowRight />} onClick={onNext} />
    </HStack>
  )
}