import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { Logo } from '@/components/Logo/Logo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser, useMagicLogin } from '@/hooks/auth';
import { LOCALSTORAGE_REDIRECT_KEY, organizationsRoute } from '@/constants/routes';
import Loader from '@/components/Loader/Loader';


export function Magic() {
  const navigate = useNavigate()
  const { data } = useUser()
  const { mutate } = useMagicLogin()
  const { token } = useParams()
  const { search } = useLocation();

  const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);

  console.log({ searchParams })

  const [error, setError] = React.useState<unknown>(null);


  useEffect(() => {
    if (data?.attributes?.email) {
      const wantedRedirect = searchParams.get("redirect") ?? localStorage.getItem(LOCALSTORAGE_REDIRECT_KEY)

      if (wantedRedirect && wantedRedirect.length > 0) {
        console.log(`Removing ${wantedRedirect} for redirect`)
        localStorage.removeItem(LOCALSTORAGE_REDIRECT_KEY)
        navigate(wantedRedirect)
      } else {
        navigate(organizationsRoute(data?.attributes?.organization_id))
      }
    }
  }, [data, navigate, searchParams])

  useEffect(() => {
    if (token && !data) {
      try {
        console.log("Login !")
        mutate({ token })
      } catch (err: any) {
        if (err.error) {
          setError(err.error);
        }
      }
    }
  }, [token, mutate, data])

  return (
    <Flex
      as="section"
      direction={{ base: 'column', lg: 'row' }}
      height="100vh"
      bg={useColorModeValue('gray.50', 'gray.900')}
      overflowY="auto"
    >
      <Container py="8" px={0} flex="1">
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '3', sm: '6' }}>
            <Stack spacing="8">
              <Stack spacing="6">
                <VStack justifyContent="center">
                  <Logo withIcon maxW='8em' />
                </VStack>
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                  <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                    Connexion en cours
                  </Heading>
                </Stack>
              </Stack>
              <Box
                py={{ base: '4', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius={{ base: 'xl', sm: 'xl' }}
              >
                <Loader />
              </Box>
            </Stack>
          </Container>
        </Stack>
      </Container>
    </Flex>

  )
}
