
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { RidesList } from './RidesList';
import { Button, useToast } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { ridesNewRoute } from '@/constants/routes';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useMutation } from '@tanstack/react-query';
import { exportRides } from '@/api/ride';


export const RidesPage = () => {
  const toast = useToast()

  const { orgId } = useParams()
  const { ability } = useOrganizations()

  const exportRideMutation = useMutation({
    mutationFn: exportRides
  })

  const onExport = async () => {
    console.log("Export !", { orgId })
    exportRideMutation.mutate(`${orgId}`, {
      onSuccess: (data, variables, context) => {
        toast({ title: 'Export en cours', description: 'Votre fichier vous sera envoyé par email dans les plus bref délais !', status: 'success', isClosable: true, duration: 5000 })
      },
      onError: (error: Error) => {
        toast({ title: 'Erreur lors de l\'export', description: error.message, status: 'error', isClosable: true, duration: 5000 })
      }
    })
  }

  return (
    <PageWithTitle
      title="Trajets"
      actions={[
        <Button isLoading={exportRideMutation.isPending} isDisabled={exportRideMutation.submittedAt > 0} key="export" size={'sm'} variant="primary" onClick={onExport}>Exporter</Button>
      ]}
    >
      <BoxSection px={0}>
        <RidesList />
      </BoxSection>
    </PageWithTitle>
  );
}

export default RidesPage;