
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Divider, HStack, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { Formats, View, Views } from 'react-big-calendar'
import { EquipmentCalendar, Event } from '@/components/EquipmentCalendar/EquipmentCalendar';
import { useNanuqQuery } from '@/hooks/query';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import { getMissionEquipmentsPlanningForStructure, getMissionEquipmentsPlanningForStructureKey, removeEquipmentFromMission } from '@/api/missionEquipment';
import { addDays, addMonths, endOfMonth, endOfWeek, formatISO, isBefore, parseISO, startOfHour, startOfMonth, startOfWeek } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import { DateRangeHeader } from '@/components/DateRangeHeader/DateRangeHeader';
import { MissionEquipmentPlanningList } from '@/screens/MissionEquipmentPlanningList/MissionEquipmentPlanningList';
import { colorsForCode } from '@/utils/missions';
import { StructureSelectHeader } from '@/components/StructureSelectHeader/StructureSelectHeader';
import QueryString from 'qs';
import { RecordId } from '@/interfaces/id';
import MissionDetailForEquipmentModal from '@/screens/MissionDetailForEquipmentModal/MissionDetailForEquipmentModal';
import { ApiCollectionResponse, ApiErrorResponse, ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { VehiculesListQueryParams } from '../Vehicules/VehiculesList';
import { NewReservationModal } from './NewReservationModal';
import { MissionEquipmentPlanning, MissionEquipmentPlanningMeta } from '@/interfaces/missionEquipment';
import { RemoteInfiniteAutoFetchContainer } from '@/components/RemoteFetchContainer/RemoteInfiniteAutoFetchContainer';


export const ReservationsList = () => {

  const { orgId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedEvent, setSelectedEvent] = useState<{ id: RecordId, mission_id: RecordId, equipment_id: RecordId } | null>(null)
  const [selectedRange, setSelectedRange] = useState<VehiculesListQueryParams | null>(null)

  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })

  const [currentRange, setCurrentRange] = useState([
    queryParams.available_between?.start_date ? parseISO(queryParams.available_between?.start_date) : startOfMonth(new Date()),
    queryParams.available_between?.end_date ? parseISO(queryParams.available_between?.end_date) : endOfMonth(new Date()),
  ])


  const queryClient = useQueryClient();

  const onNavigate = (newDate: Date, view: View) => {
    console.log('onNavigate:', { newDate, view })
    const rangeStartBoudary = startOfMonth(newDate)
    setCurrentRange([rangeStartBoudary, addMonths(rangeStartBoudary, 1)])
  }

  const onDateChange = (newDate: Date) => {
    console.log('onDateChange:', { newDate })
    setCurrentRange([newDate, addMonths(newDate, 1)])
  }

  const formats: Formats = {
    dayFormat: (date, culture, localizer) =>
      localizer?.format(date, 'dd', culture) || '',
    weekdayFormat: (date, culture, localizer) =>
      localizer?.format(date, 'EE', culture) || '',
  }

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => {
      const {
        bg: eventBg,
        border: eventBorder,
        fg: eventFg,
        subtle: eventSubtle
      } = colorsForCode(event.mission_type)

      return ({
        ...(isSelected && {
          style: {
            backgroundColor: eventFg,
            color: 'white'
          },
        } || {
          style: {
            backgroundColor: eventBg,
            color: eventFg
          }
        }),
      })
    },
    []
  )


  const planningKey = [getMissionEquipmentsPlanningForStructureKey, currentRange, queryParams.structure_id]

  const planning = useInfiniteQuery({
    queryKey: [getMissionEquipmentsPlanningForStructureKey, currentRange, queryParams.structure_id, 'infinite'],
    queryFn: ({ pageParam }) => queryParams.structure_id ?
      getMissionEquipmentsPlanningForStructure(queryParams.structure_id, pageParam, { by_period: { started_at: addDays(currentRange[0], -3), ended_at: addDays(currentRange[1], 3) } }) :
      null,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage?.meta?.next
  })

  // const {

  //   fetchNextPage,
  //   hasNextPage,
  //   isFetching,
  //   isFetchingNextPage,
    
  // } = planning


  const { mutate: deleteMe, isSuccess, data: deletedMe, reset } = useMutation({
    mutationFn: (missionEquipmentId: RecordId) => removeEquipmentFromMission(missionEquipmentId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: planningKey })
      await planning.refetch()
    }
  })

  const onStructureChange = (structure_id: RecordId | undefined) => {
    setSearchParams(QueryString.stringify({ ...queryParams, structure_id }))
  }

  const onSelectEvent = (event: any) => {
    console.log({ event })
    setSelectedEvent(event)
  }

  const onRangeChange = (event: any) => {
    console.log('onRangeChange', { event })
  }

  const onSelectSlot = (event: any) => {
    const start = isBefore(event.start, new Date()) ? startOfHour(new Date()) : event.start
    const params: VehiculesListQueryParams = {
      ...queryParams,
      available_between: { start_date: formatISO(start), end_date: formatISO(event.end) },

    }
    console.log("Slot:", { event, params })
    setSelectedRange(params)
  }

  const onSelecting = (range: any) => {
    console.log("onSelecting:", { range })
    // setSelectedEvent(event)
    return true
  }


  const onCancelEvent = () => {
    const toCancel = selectedEvent
    console.log({ toCancel })
    if (toCancel) {
      deleteMe(toCancel.id)
    }
    setSelectedEvent(null)
  }

  return (
    <Box>
      <Box mx={{ base: '0', md: '6' }} px={{ base: '3', md: '0' }} py={3}>
        <HStack>
          <StructureSelectHeader
            flex={1}
            structureId={`${queryParams.structure_id}`}
            onStructureChange={onStructureChange}
          />
        </HStack>
      </Box>
      <BoxSection px={0} bg={'bg.surface'}>
        <Box p={3}>
          <VStack spacing={3} alignItems={'stretch'}>
            <Box p={3}>
              <DateRangeHeader
                onDateChange={onDateChange}
                date={currentRange[0]}
              />
            </Box>
            <RemoteInfiniteAutoFetchContainer<
              MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta>
              {...planning}
              render={(data) => {

              const filteredData: Event[] = data?.data.map(r => ({
                title: r.attributes.equipment_name,
                id: r.attributes.id,
                mission_id: r.attributes.mission_id,
                equipment_id: r.attributes.equipment_id,
                mission_type: r.attributes.mission_type,
                start: parseISO(r.attributes.begin_at),
                end: parseISO(r.attributes.end_at)
              })) || []

              return (<VStack alignItems={'stretch'}>
                  <HStack spacing={6} justifyContent={'space-between'} alignItems={{ base: 'stretch', lg: 'flex-start' }} flexDirection={{ base: 'column', lg: 'row' }} flex={1}>
                  {!isMobile && <Box
                      maxH={{ base: '100%', xl: '60vh' }}
                      overflowY={'auto'}
                      px={3}
                      flex={'1'}
                    >
                      <EquipmentCalendar
                        events={filteredData}
                        onNavigate={onNavigate}
                        date={currentRange[0]}
                        selectable
                        showMultiDayTimes
                        onSelectEvent={onSelectEvent}
                        onSelectSlot={onSelectSlot}
                        onSelecting={onSelecting}
                        defaultView={Views.MONTH}
                        drilldownView={Views.MONTH}
                        formats={formats}
                        toolbar={false}
                        eventPropGetter={eventPropGetter}
                      />
                    </Box>}
                    <Box
                      px={3}
                      maxH={{ base: '100%', xl: '60vh' }}
                      overflowY={{ base: 'initial', xl: 'auto' }}
                      flex={'0 1 400px'}
                    >
                    <MissionEquipmentPlanningList
                      onSelectEvent={onSelectEvent}
                      events={data?.data || []}
                      onNew={() => setSelectedRange(queryParams)}
                    />
                    </Box>
                  </HStack>
                  <Divider />
                  <Box p={3}>
                    <DateRangeHeader
                      onDateChange={onDateChange}
                      date={currentRange[0]}
                    />
                  </Box>
                </VStack>)
              }} />
          </VStack>
          <MissionDetailForEquipmentModal
            onClose={() => setSelectedEvent(null)}
            isOpen={selectedEvent !== null}
            onCancel={onCancelEvent}
            equipmentId={selectedEvent?.equipment_id}
            missionId={selectedEvent?.mission_id}
          />
          <NewReservationModal
            onClose={() => setSelectedRange(null)}
            isOpen={selectedRange !== null}
            params={selectedRange}
          />
        </Box>
      </BoxSection>
    </Box>
  );
}

export default ReservationsList;