import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  VStack,
  HStack,
  Text,
  Divider,
} from '@chakra-ui/react'

import { RecordId } from '@/interfaces/id';
import { useNanuqQuery } from '@/hooks/query';
import { getMission, getMissionKey } from '@/api/mission';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import { getEquipment, getEquipmentKey } from '@/api/equipment';
import { VehiculeDetails } from '../VehiculeDetails/VehiculeDetails';
import { TbCalendar } from 'react-icons/tb';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { missionEditRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import Marker from '@/components/Marker/Marker';

export interface MissionDetailForEquipmentModalProps extends Partial<ModalProps> {
  isOpen: boolean
  onClose: () => void
  onCancel: () => void
  missionId?: RecordId | undefined
  equipmentId?: RecordId | undefined
}

const MissionDetailForEquipmentModal = ({ isOpen, onClose, onCancel, missionId, equipmentId, ...props }: MissionDetailForEquipmentModalProps) => {

  const { orgId } = useParams()

  const missionQuery = useNanuqQuery([getMissionKey, missionId], () => missionId ? getMission(missionId) : null)
  const equipmentQuery = useNanuqQuery([getEquipmentKey, equipmentId], () => equipmentId ? getEquipment(equipmentId) : null)

  const { ability } = useOrganizations()
  const canUpdate = ability?.can('update', 'mission', missionQuery.data?.data.attributes)

  return (
    <Modal {...props} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <RemoteFetchContainer {...equipmentQuery} render={(equipment) => (
          <ModalContent>
            <ModalHeader>{equipment?.data.attributes.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3} alignItems={'stretch'}>
                {equipment && equipment.data.meta?.equipment_type.category == "vehicule" && <VehiculeDetails data={equipment?.data} />}
                <Divider />
                <RemoteFetchContainer {...missionQuery} render={(mission) => (
                  <VStack flex={1} alignItems={'stretch'}>
                    <HStack alignItems={'center'}>
                      <Text color='brand.600'><TbCalendar /></Text>
                      <Text><b>Réservation</b></Text>
                    </HStack>
                    <HStack spacing={1} flexWrap={'wrap'}>
                      <Text><Marker color='yellow'>{mission?.data.attributes.name}</Marker></Text>
                    </HStack>
                    <HStack spacing={1} flexWrap={'wrap'}>
                      <Text>Du {parseAndFormatDateShort(mission?.data.attributes.begin_at)}</Text>
                      <Text>au {parseAndFormatDateShort(mission?.data.attributes.end_at)}</Text>
                    </HStack>
                    <HStack spacing={1} flexWrap={'wrap'}>
                      <Text color={'fg.subtle'}>Par {mission?.data.meta?.user.full_name}</Text>
                    </HStack>
                  </VStack>
                )} />
                <Divider />
                <VStack flex={1} alignItems={'stretch'}>
                  <Button colorScheme='brand' isDisabled={missionQuery.isLoading || !canUpdate} as={RouterLink} to={canUpdate ? missionEditRoute(orgId, missionId) : ''}>Modifier la réservation</Button>
                  <Button isDisabled={missionQuery.isLoading || !canUpdate} variant={'outline'} onClick={onCancel}>Annuler la réservation</Button>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        )} />
      </Modal>
  )
}

export default MissionDetailForEquipmentModal