
import { getMission, getMissionKey, getMissionQuery } from '@/api/mission';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { MissionAttributesMeta, MissionWithEquipmentsAttributes } from '@/interfaces/mission';
import { ApiResourceResponse } from '@/utils/api';
import { Box, HStack, Heading, Tag, Text, VStack } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams } from 'react-router-dom';
import { AsyncVehiculeCard } from '@/screens/VehiculeCard/AsyncVehiculeCard';
import { formatDateShort, parseAndFormatDateShort } from '@/utils/datesFormat';
import { parseISO } from 'date-fns';
import { TbArrowRight, TbCalendar, TbTable, TbTag, TbUser } from 'react-icons/tb';
import { MdOutlineLocationOn } from 'react-icons/md';
import ValueWithLabel from '@/components/ValueWithLabel/ValueWithLabel';
import { MISSION_CODES } from '@/constants/mission';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getMissionQuery(params.missionId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getMissionQuery(params.missionId as RecordId))
    } 
    return data
  }
)

export const MissionSummary = () => {

  const { orgId, missionId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<MissionWithEquipmentsAttributes, unknown, MissionAttributesMeta>;

  const { data: mission } = useNanuqQuery([getMissionKey, missionId], () => missionId ? getMission(missionId) : null, { initialData })

  if (!mission) {
    return <></>
  }

  return (
    <VStack alignItems={'stretch'}>
      <HStack flexDirection={{ base: 'column', lg: 'row' }} justify={'flex-start'} alignContent={'stretch'} flexWrap={'wrap'} alignItems={{ base: 'stretch', lg: 'flex-start' }}>
      <VStack p={3} alignItems={'stretch'} flex={1}>
        <Heading size={'xs'}>Détails</Heading>
          <VStack spacing={4} flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'} key={missionId}>
            <ValueWithLabel icon={<TbCalendar />} label='Dates'>
              <Text>Du {parseAndFormatDateShort(mission?.data.attributes.begin_at)}</Text>
              <Text>au {parseAndFormatDateShort(mission?.data.attributes.end_at)}</Text>
            </ValueWithLabel>
            <ValueWithLabel icon={<TbUser />} label='Responsable'>
              <Text>{mission?.data.meta?.user.full_name}</Text>
            </ValueWithLabel>
            <ValueWithLabel icon={<TbTag />} label='Catégorie'>
              <Text>{MISSION_CODES[mission?.data.attributes.mission_type]}</Text>
            </ValueWithLabel>
            <ValueWithLabel icon={<MdOutlineLocationOn />} label='Structure'>
              <Text>{mission?.data?.meta?.structure?.short_name}</Text>
            </ValueWithLabel>
          </VStack>
      </VStack>
      <VStack p={3} alignItems={'stretch'} flex={1}>
        <Heading size={'xs'}>Réservations</Heading>
        {mission.data.attributes.mission_equipments.map((equipment) => {


          return (
            <AsyncVehiculeCard orgId={orgId || ''} equipmentId={equipment.equipment_id} partialVehicule={equipment.equipment} key={equipment.equipment_id} />)
          })}
      </VStack>

      </HStack>
      {/* {mission.data.attributes.requirements.length === 0 && (
        <EmptyList actionLink={missionEditRoute(orgId, missionId)} actionText='Configurer'>
          Aucun matériel requis
        </EmptyList>
      )} */}
    </VStack>
  );
}

export default MissionSummary;