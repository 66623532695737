
import { deleteMission, getOrganizationMissions, getOrganizationMissionsKey } from '@/api/mission';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { MissionAttributes } from '@/interfaces/mission';
import { PaginationState } from '@tanstack/react-table';
import { MissionTable } from '@/screens/MissionTable/MissionTable';
import { useState } from 'react';


export const MissionsList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()

  const [{ pageIndex }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const queryKey = [getOrganizationMissionsKey, orgId, structureId, pageIndex]
  const {
    data,
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationMissions(orgId, pageIndex + 1, { structure: [structureId] }) : null
  )

  const deleteMissionMutation = useOptimisticDeleteMutation<ApiResourceResponse<MissionAttributes>, RecordId>(queryClient, deleteMission, queryKey)

  const onEdit = async () => {

  }

  const onDelete = async (missionId: RecordId) => {
    console.log("Delete !", { missionId })
    deleteMissionMutation.mutate(missionId)
  }

  return (
    data?.data && <MissionTable onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} data={data} />
  );
}
