import { Calendar, CalendarProps, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import fr from 'date-fns/locale/fr'
import './styles/styles.scss'
import { RecordId } from '@/interfaces/id'

const locales = {
  'fr': fr,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const messages = {
  date: 'Date',
  time: 'Heure',
  event: 'Évenement',
  allDay: 'Toute la journée',
  week: 'Semaine',
  work_week: 'Semaine de bureau',
  day: 'Jour',
  month: 'Mois',
  previous: 'Précédent',
  next: 'Suivant',
  yesterday: 'Hier',
  tomorrow: 'Demain',
  today: 'Aujourd\'hui',
  agenda: 'Agenda',

  noEventsInRange: 'Aucun évenement',
  showMore: (total) => `+${total}`,
}

export interface Event {
  title: string,
  start: Date,
  end: Date,
  allDay?: boolean
  resource?: any,
}

export interface EquipmentCalendarProps extends Partial<CalendarProps> {
  events: Event[]
}


export function EquipmentCalendar({
  events,
  ...props
}: EquipmentCalendarProps) {

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      culture="fr"
      messages={messages}
      style={{ minHeight: 500, flex: "1" }}
      popup
      {...props}
    />
  )
}