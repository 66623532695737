import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  HStack,
  FormHelperText,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateMissionAttributes, MissionAttributes } from '@/interfaces/mission';
import { AsyncSelect } from '@/components/AsyncSelect/AsyncSelect';
import { useNanuqQuery } from '@/hooks/query';
import { createMission } from '@/api/mission';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResource, ApiResourceResponse } from '@/utils/api';
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { addHours, addMinutes, format, formatISO, isBefore, isFuture, isToday, parseISO, startOfHour, startOfMinute } from 'date-fns';
import { Select } from 'chakra-react-select';
import { MISSION_TYPES_SELECT } from '@/constants/mission';
import { formatDateShort, toLocalDateTimeFormat } from '@/utils/datesFormat';
import { zonedTimeToUtc } from 'date-fns-tz';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import { MissionEquipmentPlanning } from '@/interfaces/missionEquipment';

export interface SimpleMissionFormProps {
  mission?: Partial<MissionAttributes>,
  equipmentId?: RecordId,
  orgId: RecordId,
  planning?: ApiResource<MissionEquipmentPlanning, unknown>[],
  onSave: (newMission: ApiResourceResponse<MissionAttributes>) => Promise<any>
}

const NewSimpleMissionForm = ({ mission, equipmentId, orgId, onSave, planning }: SimpleMissionFormProps) => {

  const nextMission = planning ? planning.map(p => parseISO(p.attributes.begin_at)).filter(e => isFuture(e)).sort()[0] : null
  
  const defaultEndDate = startOfHour(addHours(new Date(), 5));
  const suggestedEndDate = nextMission && isToday(nextMission) ? addMinutes(nextMission, -1) : defaultEndDate

  const initialValues: CreateMissionAttributes = {
    name: mission?.name || '',
    begin_at: toLocalDateTimeFormat(mission?.begin_at ? parseISO(mission.begin_at) : startOfMinute(new Date())),
    end_at: toLocalDateTimeFormat(mission?.end_at ? parseISO(mission.end_at) : suggestedEndDate),
    mission_type: mission?.mission_type || '',
    structure_id: mission?.structure_id || -1,
    mission_equipments_attributes: equipmentId && [
      { equipment_id: equipmentId }
    ] || undefined
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (missionPayload: CreateMissionAttributes) => createMission(orgId || '', missionPayload)
  })

  const onSubmit = async (
    values: CreateMissionAttributes,
    actions: FormikHelpers<CreateMissionAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newValues: CreateMissionAttributes = {
          ...values,
          begin_at: formatISO(zonedTimeToUtc(values.begin_at, 'Europe/Paris')),
          end_at: formatISO(zonedTimeToUtc(values.end_at, 'Europe/Paris'))
        }
        const newData = await mutateAsync(newValues);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  const structureQuery = useNanuqQuery([getOrganizationStructuresKey, orgId], () => getOrganizationStructures(orgId))

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="mission"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        
        <VStack spacing="6" alignItems={'flex-start'}>
          <Field name='name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>
                <FormLabel htmlFor='name'>Nom</FormLabel>
                <Input {...field} isRequired id='name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='mission_type'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.mission_type && form.touched.mission_type ? true : false}>
                <FormLabel htmlFor='mission_type'>Type</FormLabel>
                <Select
                  isRequired
                  defaultValue={MISSION_TYPES_SELECT.find(e => e.value === field.value)}
                  isInvalid={form.errors.mission_type ? true : false}
                  placeholder={`Sélectionner`}
                  name={field.name}
                  options={MISSION_TYPES_SELECT}
                  value={MISSION_TYPES_SELECT.find(e => e.value === field.value)}
                  onChange={(opt) => form.setFieldValue(field.name, opt?.value)}
                />
                <FormHelperText>Le code analytique de la mission.</FormHelperText>
                <FormErrorMessage>{form.errors.mission_type as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='begin_at'>
            {({ field, form, ...other }: FieldProps) => (
              <FormControl isInvalid={form.errors.begin_at && form.touched.begin_at ? true : false}>
                <FormLabel htmlFor='begin_at'>Date de début</FormLabel>
                <Input
                  {...field}
                  isRequired
                  min={format(startOfHour(new Date()), "yyyy-MM-dd'T'HH:mm")}
                  aria-label="De"
                  type="datetime-local"
                />
                <FormHelperText>La date et l'heure de début de la réservation.</FormHelperText>
                <FormErrorMessage>{form.errors.begin_at as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='end_at'>
            {({ field, form, ...other }: FieldProps) => (
              <FormControl isInvalid={form.errors.end_at && form.touched.end_at ? true : false}>
                <FormLabel htmlFor='end_at'>Date de fin</FormLabel>
                                
                <Input
                  {...field}
                  max={nextMission && values.begin_at && isBefore(parseISO(values.begin_at), nextMission) ? formatISO(zonedTimeToUtc(nextMission, 'Europe/Paris')) : undefined}
                  isRequired
                  aria-label="A"
                  min={form.values.begin_at}
                  type="datetime-local"
                />
                <FormHelperText>La date approximative de retour.{' '}</FormHelperText>
                {nextMission && <FormHelperText><mark>Prochaine mission le {formatDateShort(nextMission)}.</mark></FormHelperText>}

                <FormErrorMessage>{form.errors.end_at as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='structure_id'>
            {(formProps: FieldProps) => (
              <AsyncSelect
                query={structureQuery}
                label="Structure"
                helperText="L'unité locale ou la DT mandataire de la mission"
                {...formProps}
              />
            )}
          </Field>

          {props.errors['mission_equipments.equipment_id'] && (
            <ErrorBox error={{error: props.errors['mission_equipments.equipment_id'][0]}} message={'Dates invalides'}></ErrorBox>
          )}

          <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
            <Button
              variant="primary"
              isLoading={props.isSubmitting}
              isDisabled={!props.dirty || !props.isValid}
              type='submit'
              w={'100%'}
            >
              Créer
            </Button>
          </HStack>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default NewSimpleMissionForm