import { useField, useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react"
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { AsyncSelect, OptionList } from "@/components/AsyncSelect/AsyncSelect"
import { useNanuqQuery } from "@/hooks/query"
import { getOrganizationMissions, getOrganizationMissionsKey } from "@/api/mission"
import { MissionAttributes } from "@/interfaces/mission";
import { ApiResourceResponse } from "@/utils/api";
import { useUser } from "@/hooks/auth";
import { EquipmentAttributes } from "@/interfaces/equipment";
import { EquipmentReservationFormParams } from "../Reservation/Modal";
import NewSimpleMissionForm from "@/screens/MissionForm/NewSimpleMissionForm";
import { RecordId } from "@/interfaces/id";
import { getMissionEquipmentsPlanningForEquipment, getMissionEquipmentsPlanningForEquipmentKey } from "@/api/missionEquipment";
import { addMinutes, isPast, parseISO } from "date-fns";
import { MissionAsyncSelect } from "@/components/MissionAsyncSelect/MissionAsyncSelect";
import { VehiculesListQueryParams } from "@/pages/Vehicules/VehiculesList";


export interface NewTakeoutMissionFieldProps {
  name: string,
  equipment?: EquipmentAttributes,
  queryParams?: VehiculesListQueryParams | null,
  onTakeout?: (equipmentId: RecordId, missionId: RecordId) => void
}

export const NewTakeoutMissionField = (props: NewTakeoutMissionFieldProps) => {
  const { name, equipment, onTakeout, queryParams } = props
  const equipmentId = equipment?.id
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { orgId } = useParams()
  const { data: user } = useUser()

  const missionQuery = useNanuqQuery(
    [getOrganizationMissionsKey],
    () => getOrganizationMissions(orgId || '', 1, { active: true })
  )
  const { data: planning } = useNanuqQuery([getMissionEquipmentsPlanningForEquipmentKey, equipmentId], () => equipmentId ? getMissionEquipmentsPlanningForEquipment(equipmentId) : null)

  const [field, meta] = useField(props);

  const form = useFormikContext<EquipmentReservationFormParams>();
  const [additional, setAdditional] = useState<OptionList>([{ label: 'Nouvelle mission', value: 'new' }])
  
  const {
    values: { mission_id },
    touched,
    setFieldValue,
    setValues,
  } = form

  useEffect(() => {
    console.log("Mission id = ", { mission_id, touched: touched.mission_id, isOpen })
    if (
      mission_id === 'new' && !isOpen
    ) {
      onOpen()
    }
  }, [mission_id, touched.mission_id, onOpen, isOpen]);

  const onMissionSave = async (newMission: ApiResourceResponse<MissionAttributes, unknown, unknown>) => {
    console.log("New mission saved: ", newMission)

    setAdditional([
      { label: 'Nouvelle mission', value: 'new' },
      { label: newMission.data.attributes.name, value: `${newMission.data.id}` },
    ])

    await setFieldValue('mission_id', `${newMission.data.id}`)
    await setValues({ mission_id: `${newMission.data.id}` })

    onClose()
    if (onTakeout && equipment?.id && isPast(addMinutes(parseISO(newMission.data.attributes.begin_at), 5))) {
      onTakeout(equipment?.id, newMission.data.id)
    }
  }

  const onModalClose = () => {
    setFieldValue('mission_id', null)
    onClose()
  }

  const defaultStructureId = useMemo(() => {
    if (queryParams?.structure_id) {
      return parseInt(queryParams?.structure_id.toString())
    } else if (user?.meta?.user_structures && user?.meta?.user_structures?.length > 0) {
      return user?.meta?.user_structures[0].structure_id
    }
  }, [user?.meta?.user_structures, queryParams?.structure_id])

  const initialMission: Partial<MissionAttributes> = {
    name: `Résa ${equipment?.name}`,
    mission_type: 'Autre',
    begin_at: queryParams?.available_between?.start_date,
    end_at: queryParams?.available_between?.end_date,
    structure_id: defaultStructureId
  }


  return (<>
      <MissionAsyncSelect
        query={missionQuery}
        label="Mission"
        additionalOptions={additional}
        field={field}
        form={form}
        meta={meta}
      />
      <Modal size='xl' isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Créer une nouvelle mission</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <NewSimpleMissionForm
            equipmentId={equipment?.id}
            orgId={`${orgId}`}
            planning={planning?.data}
            onSave={onMissionSave}
            mission={initialMission}
          />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
