import { Badge, Button, HStack, Text, VStack } from "@chakra-ui/react"
import { ApiResource } from "@/utils/api"
import { useOrganizations } from "@/contexts/useOrganizationsContext"
import { formatDateShort } from "@/utils/datesFormat"
import { addMinutes, eachDayOfInterval, formatISO, isPast, isSameDay, parseISO, startOfDay } from "date-fns"
import { MissionEquipmentPlanning } from "@/interfaces/missionEquipment"
import { colorsForCode } from "@/utils/missions"
import { useMemo } from "react"
import { TbCalendarEvent, TbPlus } from "react-icons/tb"


export interface MissionEquipmentPlanningListProps {
  events: ApiResource<MissionEquipmentPlanning, unknown>[],
  onSelectEvent: (event: MissionEquipmentPlanning) => void
  onNew?: () => void
}

export const MissionEquipmentPlanningItem = ({
  event,
  onSelectEvent
}: {
  event: ApiResource<MissionEquipmentPlanning, unknown>,
  onSelectEvent: (event: MissionEquipmentPlanning) => void
}) => {

  const {
    bg: eventBg,
    border: eventBorder,
    fg: eventFg,
    subtle: eventSubtle
  } = colorsForCode(event.attributes.mission_type)

  const beginDate = parseISO(event.attributes.begin_at)
  const endDate = parseISO(event.attributes.end_at)
  const past = isPast(endDate)

  return (
    <VStack
      key={event.id}
      spacing={3}
      flex={1}
      bgColor={eventBg}
      color={eventFg}
      border={`1px solid ${eventBorder}`}
      borderRadius={4}
      alignItems={'stretch'}
      onClick={() => onSelectEvent(event.attributes)}
    >
      <HStack opacity={past ? '0.6' : '1'} px={3} py={1} justifyContent={'space-between'}>
        <VStack spacing={0} alignItems={'stretch'}>
          <Text>{event.attributes.equipment_name}</Text>
          <Text color={eventSubtle}>{event.attributes.mission_name}</Text>
        </VStack>
        <Text fontWeight={'bold'}>{formatDateShort(beginDate, {}, 'HH:mm')} - {formatDateShort(endDate, {}, 'HH:mm')}</Text>
      </HStack>
    </VStack>
  )
}

export const MissionEquipmentPlanningList = ({
  events,
  onSelectEvent,
  onNew
}: MissionEquipmentPlanningListProps) => {

  const { ability, selectedOrganizationId } = useOrganizations()

  const keyForDateInterval = (date: Date) => formatISO(date)

  const groupedEvents: { [key: string]: ApiResource<MissionEquipmentPlanning, unknown>[] } = useMemo(() => events.reduce((prev, curr) => {

    let end = parseISO(curr.attributes.end_at)
    if (end.getHours() === 0 && end.getMinutes() === 0) {
      end = addMinutes(end, -1)
    }
    const eventDays = eachDayOfInterval({ start: parseISO(curr.attributes.begin_at), end })
    eventDays.forEach(ed => {
      prev[keyForDateInterval(ed)] = prev[keyForDateInterval(ed)] || []
      const newEvent = {
        ...curr,
        attributes: {
          ...curr.attributes,
          begin_at: isSameDay(ed, parseISO(curr.attributes.begin_at)) ? curr.attributes.begin_at : formatISO(ed),
          end_at: isSameDay(ed, parseISO(curr.attributes.end_at)) ? curr.attributes.end_at : formatISO(ed),
        }
      }
      prev[keyForDateInterval(ed)].push(newEvent)
    })
    // console.log({ eventDays, curr: curr.attributes.mission_name })
    return prev
  }, {}), [events])
  
  return (
    <VStack
      borderRadius={6}
      flexGrow={1}
      flexShrink={0}
      flexBasis={{ base: '100%', md: '250px' }}
      spacing={3}
      alignItems={'stretch'}
    >
      {events && Object.keys(groupedEvents).map(key => (
        <VStack key={key} spacing={3} flex={1} alignItems={'stretch'}>
          <HStack justifyContent={'center'} alignItems={'stretch'} textAlign={'center'}>
            {isPast(parseISO(key)) ? (
              <Text opacity='0.6' textAlign={'center'}>{formatDateShort(parseISO(key), {}, 'd LLL')}</Text>
            ) : (
              <Text textAlign={'center'}>{formatDateShort(parseISO(key), {}, 'd LLL')}</Text>
            )}
            {isSameDay(parseISO(key), new Date()) && <Badge>Aujourd'hui</Badge>}
          </HStack>
          {groupedEvents[key]?.map(event => <MissionEquipmentPlanningItem onSelectEvent={onSelectEvent} key={`${key}-${event.id}-${event.attributes.begin_at}`} event={event} /> )}
        </VStack>
      ))}
      {events.length === 0 && (<VStack p={3}>
        <Text color={'fg.subtle'} textAlign={'center'}>Aucune réservation</Text>
      </VStack>)}
      {onNew && (<VStack pt={3}>
        <Button size={'md'} w={'100%'} onClick={onNew} textAlign={'center'} leftIcon={<TbPlus />} variant={'outline'}>Nouvelle réservation</Button>
      </VStack>)}
    </VStack>
  )
}