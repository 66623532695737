
import { getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Box, HStack, Divider, VStack, useBreakpointValue } from '@chakra-ui/react';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { getMissionEquipmentsPlanningForEquipment, getMissionEquipmentsPlanningForEquipmentKey, removeEquipmentFromMission } from '@/api/missionEquipment';
import { addDays, addMonths, endOfMonth, formatISO, isBefore, parseISO, startOfHour, startOfMonth } from 'date-fns';

import { EquipmentCalendar, Event } from '@/components/EquipmentCalendar/EquipmentCalendar';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import { Formats, View, Views } from 'react-big-calendar';
import { DateRangeHeader } from '@/components/DateRangeHeader/DateRangeHeader';
import { VehiculesListQueryParams } from '../Vehicules/VehiculesList';
import { colorsForCode } from '@/utils/missions';
import { MissionEquipmentPlanningList } from '@/screens/MissionEquipmentPlanningList/MissionEquipmentPlanningList';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import MissionDetailForEquipmentModal from '@/screens/MissionDetailForEquipmentModal/MissionDetailForEquipmentModal';
import { VehiculeReservationBoxModal } from '@/screens/EquipmentActionButton/VehiculeReservationBox/Modal';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const EquipmentReservation = () => {

  const { orgId, equipmentId } = useParams()
  const queryClient = useQueryClient()

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedEvent, setSelectedEvent] = useState<{ id: RecordId, mission_id: RecordId, equipment_id: RecordId } | null>(null)
  const [selectedRange, setSelectedRange] = useState<VehiculesListQueryParams | null>(null)


  const [currentRange, setCurrentRange] = useState([startOfMonth(new Date()), endOfMonth(new Date())])

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const equipmentPlanningKey = [getMissionEquipmentsPlanningForEquipmentKey, equipmentId, currentRange]
  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  const planning = useNanuqQuery(
    equipmentPlanningKey,
    () => equipmentId ? getMissionEquipmentsPlanningForEquipment(
      equipmentId,
      1,
      { by_period: { started_at: addDays(currentRange[0], -3), ended_at: addDays(currentRange[1], 3) } }
    ) : null
  )

  const { mutate: deleteMe, isSuccess, data: deletedMe, reset } = useMutation({
    mutationFn: (missionEquipmentId: RecordId) => removeEquipmentFromMission(missionEquipmentId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: equipmentPlanningKey })
      await planning.refetch()
    }
  })

  const onNavigate = (newDate: Date, view: View) => {
    console.log('onNavigate:', { newDate, view })
    const rangeStartBoudary = startOfMonth(newDate)
    setCurrentRange([rangeStartBoudary, addMonths(rangeStartBoudary, 1)])
  }

  const onDateChange = (newDate: Date) => {
    console.log('onDateChange:', { newDate })
    setCurrentRange([newDate, addMonths(newDate, 1)])
  }

  const formats: Formats = {
    dayFormat: (date, culture, localizer) =>
      localizer?.format(date, 'dd', culture) || '',
    weekdayFormat: (date, culture, localizer) =>
      localizer?.format(date, 'EE', culture) || '',
  }

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => {
      const {
        bg: eventBg,
        border: eventBorder,
        fg: eventFg,
        subtle: eventSubtle
      } = colorsForCode(event.mission_type)

      return ({
        ...(isSelected && {
          style: {
            backgroundColor: eventFg,
            color: 'white'
          },
        } || {
          style: {
            backgroundColor: eventBg,
            color: eventFg
          }
        }),
      })
    },
    []
  )


  const onSelectEvent = (event: any) => {
    console.log({ event })
    setSelectedEvent(event)
  }

  const onSelectSlot = (event: any) => {
    const start = isBefore(event.start, new Date()) ? startOfHour(new Date()) : event.start
    const params: VehiculesListQueryParams = {
      available_between: { start_date: formatISO(start), end_date: formatISO(event.end) },
    }
    console.log("Slot:", { event, params })
    setSelectedRange(params)
  }

  const onSelecting = (range: any) => {
    console.log("onSelecting:", { range })
    // setSelectedEvent(event)
    return true
  }

  const onCancelEvent = () => {
    const toCancel = selectedEvent
    console.log({ toCancel })
    if (toCancel) {
      deleteMe(toCancel.id)
    }
    setSelectedEvent(null)
  }


  const onNewReservation = async (equipmentId: RecordId, missionId: RecordId) => {
    setSelectedRange(null)
    await queryClient.invalidateQueries({ queryKey: equipmentPlanningKey })
    await planning.refetch()
  }

  const onOpen = () => {

  }

  if (!history) {
    return <></>
  }

  return (
    <Box px={{ base: 0, md: 3 }} py={3}>
      <Box bg="bg.surface">
        <Box p={3}>
          <DateRangeHeader
            onDateChange={onDateChange}
            date={currentRange[0]}
          />
        </Box>
        <RemoteFetchContainer {...planning} render={(data) => {

          const filteredData: Event[] = data?.data.map(r => ({
            title: r.attributes.mission_name,
            id: r.attributes.id,
            mission_id: r.attributes.mission_id,
            equipment_id: r.attributes.equipment_id,
            mission_type: r.attributes.mission_type,
            start: parseISO(r.attributes.begin_at),
            end: parseISO(r.attributes.end_at)
          })) || []

          return (<VStack alignItems={'stretch'}>
            <HStack spacing={6} justifyContent={'space-between'} alignItems={{ base: 'stretch', lg: 'flex-start' }} flexDirection={{ base: 'column', lg: 'row' }} flex={1}>
              {!isMobile && <Box
                maxH={{ base: '100%', xl: '60vh' }}
                overflowY={'auto'}
                px={3}
                flex={'1'}
              >
                <EquipmentCalendar
                  events={filteredData}
                  onNavigate={onNavigate}
                  date={currentRange[0]}
                  selectable
                  showMultiDayTimes
                  onSelectEvent={onSelectEvent}
                  onSelectSlot={onSelectSlot}
                  onSelecting={onSelecting}
                  defaultView={Views.MONTH}
                  formats={formats}
                  toolbar={false}
                  eventPropGetter={eventPropGetter}
                />
              </Box>}
              <Box
                px={3}
                maxH={{ base: '100%', xl: '60vh' }}
                overflowY={{ base: 'initial', xl: 'auto' }}
                flex={'0 1 400px'}
              >
                <MissionEquipmentPlanningList
                  onSelectEvent={onSelectEvent}
                  events={data?.data || []}
                  onNew={() => setSelectedRange(queryParams)}
                />
              </Box>
            </HStack>
            <Divider />
            <Box p={3}>
              <DateRangeHeader
                onDateChange={onDateChange}
                date={currentRange[0]}
              />
            </Box>
          </VStack>)
        }} />
        <MissionDetailForEquipmentModal
          onClose={() => setSelectedEvent(null)}
          isOpen={selectedEvent !== null}
          onCancel={onCancelEvent}
          equipmentId={selectedEvent?.equipment_id}
          missionId={selectedEvent?.mission_id}
        />
        <VehiculeReservationBoxModal
          onClose={() => setSelectedRange(null)}
          isOpen={selectedRange !== null}
          queryParams={selectedRange}
          equipmentId={selectedEvent?.equipment_id || ''}
          equipment={initialData?.data?.attributes}
          onTakeout={onNewReservation}
          onOpen={onOpen}
        />
      </Box>
    </Box>
  );
}

export default EquipmentReservation;