import React from "react"
import { QueryFunction, QueryKey, UseQueryOptions, useQuery, UseQueryResult, useInfiniteQuery } from "@tanstack/react-query"
import { useLocation } from "react-router-dom"
import { ApiErrorResponse } from "../utils/api"

export function useNanuqQuery<T>(
    queryKey: QueryKey,
    queryFn: QueryFunction<T, QueryKey>,
    options?: Partial<UseQueryOptions<T, ApiErrorResponse>>,
): UseQueryResult<T, ApiErrorResponse> {

    const optionDefaults: Partial<UseQueryOptions<T, ApiErrorResponse>> = {
        refetchInterval: 15000
    }

    const query = useQuery<T, ApiErrorResponse>({
        queryKey,
        queryFn,
        ...optionDefaults,
        ...options
    })


    return query as UseQueryResult<T, Error>
}


export function useSearchParamsParser() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}