import { EquipmentEvent } from "@/interfaces/equipment";
import { IconType } from "react-icons";
import { TbAntenna } from "react-icons/tb";
import { EVENT_LABELS, EquipmentEventMetaLabel, STATES_LABELS, StateKey } from "./states";
import { sortBy } from "./object";
import { VehiculesListQueryParams } from "@/pages/Vehicules/VehiculesList";
import { formatISO, parseISO } from "date-fns";
import QueryString from "qs";

export const EQUIPMENT_LABELS: {[key: string]: { icon: IconType }} = {
  'Radio': {
    icon: TbAntenna
  }
}

export const EQUIPMENT_CATEGORIES: {[key: string]: string} = {
  'radio': 'Radio',
  'vehicule': 'Véhicule',
  'lot': 'Lot',
  'salle': 'Salle',
  'autre': 'Autre',
}
export const EQUIPMENT_CATEGORIES_OPTIONS = Object.keys(EQUIPMENT_CATEGORIES).map(o => ({ label: EQUIPMENT_CATEGORIES[o], value: o }))

export const LABEL_FOR_ACTION_FIELDS: { [key in EquipmentEventMetaLabel]: string } = {
  notes: "Notes supplémentaires",
  destination: "Destination"
}

export const sortEvents = (allowed_transitions: EquipmentEvent[], current: StateKey): EquipmentEvent[] => {

  const sortedActions = allowed_transitions.map(tr => {
    let distance = EVENT_LABELS[tr].order - STATES_LABELS[current].order
    if (distance < 0) {
      distance = Math.abs(distance) + 5
    }

    return { name: tr, distance }
  })

  return sortedActions.sort(sortBy('distance')).map(e => e.name)
}

export const parseEquipmentQueryParams = (searchParams: URLSearchParams): VehiculesListQueryParams => {

  const parsed = QueryString.parse(searchParams.toString()) as VehiculesListQueryParams

  if (parsed['usable'] === undefined) {
    parsed.usable = true
  } else {
    parsed.usable = (`${parsed.usable}` === 'true' ? true : false)
  }


  if (parsed.available_between?.start_date) {
    parsed.available_between.start_date = formatISO(parseISO(parsed.available_between.start_date))
  }
  if (parsed.available_between?.end_date) {
    parsed.available_between.end_date = formatISO(parseISO(parsed.available_between.end_date))
  }
  return parsed
}
