import { ROUTES } from "../constants/routes";
import { MissionAttributes, CreateMissionAttributes, MissionAttributesMeta, MissionWithEquipmentsAttributes } from "../interfaces/mission";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import qs from "qs";


export const getOrganizationMissionsKey = "OrganizationMissions"
export const getMissionKey = "Mission"

export async function getOrganizationMissions(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<MissionAttributes, unknown, MissionAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.missions.list(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getMission(missionId: RecordId): Promise<ApiResourceResponse<MissionWithEquipmentsAttributes, unknown, MissionAttributesMeta>> {
  const response = await fetch(
    ROUTES.missions.get(missionId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export function getMissionQuery(missionId: RecordId) {
  return {
    queryKey: [getMissionKey, missionId],
    queryFn: () => getMission(missionId)
  }
}

export async function createMission(organizationId: RecordId, mission: CreateMissionAttributes): Promise<ApiResourceResponse<MissionAttributes, unknown, MissionAttributesMeta>> {
  const response = await fetch(
    ROUTES.missions.create(organizationId), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ mission }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateMission(missionId: RecordId, mission: CreateMissionAttributes): Promise<ApiResourceResponse<MissionAttributes, unknown, MissionAttributesMeta>> {
  const response = await fetch(
    ROUTES.missions.update(`${missionId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
      body: JSON.stringify({ mission }),
    }
  )
  return await handleApiResponse(response);
}

export async function closeMission(missionId: RecordId): Promise<ApiResourceResponse<MissionAttributes, unknown, MissionAttributesMeta>> {
  const response = await fetch(
    ROUTES.missions.close(`${missionId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
    }
  )
  return await handleApiResponse(response);
}

export async function deleteMission(missionId: RecordId): Promise<ApiResourceResponse<MissionAttributes, unknown, MissionAttributesMeta>> {
  const response = await fetch(
    ROUTES.missions.update(`${missionId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}
